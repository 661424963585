<template>
    <div class="mealre">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <div @click="handFromshow">
                <el-input
                size="small"
                class="dev_dowm"
                placeholder=""
                suffix-icon="el-icon-arrow-down"
                :readonly="true"
                >
                </el-input>
            </div>
        </div>
        <div class="attheadfrom" v-if="flag">
        <el-form
            :inline="true"
            size="small"
            :model="searchParams"
            class="demo-form-inline"
        >
           
            <!-- <el-form-item label="部门">
                <Select @handleCheckChange="handleCheckChange" ></Select>
            </el-form-item> -->
            <el-form-item label="报餐时间">
                    <el-date-picker
                    v-model="searchParams.tiemes"
                    type="datetimerange"
                    align="right"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd hh:mm"
                    :default-time="['12:00:00', '08:00:00']">
                    </el-date-picker>
            </el-form-item>

             <el-form-item label="企业" v-if="UserInfo.is_dinner_sys">
                <el-select size='small' v-model="depid" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="报餐类型">
                <el-checkbox-group v-model="searchParams.type">
                      <el-checkbox label="早餐" name="type"></el-checkbox>
                      <el-checkbox label="中餐" name="type"></el-checkbox>
                      <el-checkbox label="晚餐" name="type"></el-checkbox>
                      <el-checkbox label="宵夜" name="type"></el-checkbox>
                  </el-checkbox-group>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{
                    $t("devtable.cx")
                }}</el-button>
            </el-form-item>

        </el-form>
        </div>

        <Tables @handlerow="handlerow"
      @handlpages="handlpages"
      @handleSizeChange="handleSizeChange"
      :tableLabel="tableLabel"
      :tableData="tableData"
      :flags="flags"
    
      :configs="configs"></Tables>

    </div>
</template>

<script>
import Tables from '@/components/table'
import Select from '@/components/selectree/wltreeselect'
import {formatDate} from '@/utils/date.js'
export default {
    components:{
        Select,
        Tables
    },
    data(){
        return{
            options:[],
            values:[],
            depid:'',
            flag:false,
            searchParams:{
                name:'',
                serial_number:'',
                times:'',
                dept_id:'',
                type:[],
                is_dinner_sys:''
            },
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
            tableLabel:[
            
           
            {
              prop: "apply_time",
              label: '日期',
              align: "center",
            },
            {
              prop: "enterprise_name",
              label: '企业部门',
              align: "center",
            },
            {
              prop: "total",
              label: '报餐人数',
              align: "center",
            },
            {
              prop: "breakfast",
              label: '早餐报餐数',
              align: "center",
            },
            {
              prop: "lunch",
              label: '中餐报餐数',
              align: "center",
            },
            {
              prop: "supper",
              label: '晚餐报餐数',
              align: "center",
            },
            {
              prop: "night_meal",
              label: '宵夜报餐数',
              align: "center",
            },
            
            ],
            tableData:[],
          
          // 分页数据
          configs: {
            page_index:1,
            pagesize: 10,
            total: 20,
            loading: false, // 加载状态
          },
          flags: {
            show: false, // 多选是否显示
            ishow: true, // 序号是否显示
          },
        }
    },
    mounted(){
        if(this.UserInfo.is_dinner_sys==1){
            this.handlentpw()
        }
        this.handleMealsta()
    },
    methods:{
        handFromshow(){
            this.flag = !this.flag
        },
         // 查询企业
        handlentpw(){
            let data={
                method:'co.dinner.enterprise.list',
                agent_id:this.UserInfo.agent_id
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.options = res.data.data
                }
            })
        },
        handleMealsta(v){
            let type=[]
            if(this.searchParams.type){
                this.searchParams.type.forEach(el=>{
                    if(el=='早餐'){
                        type.push('0')
                    }
                    if(el=='中餐'){
                        type.push('1')
                    }
                    if(el=='晚餐'){
                        type.push('2')
                    }
                    if(el=='宵夜'){
                        type.push('3')
                    }
                })
            }
            let data={
                method:'co.dinner.day.report',
                page_index:v?v:'1',
                page_size:10,
                agent_id:this.UserInfo.agent_id,
                is_dinner_sys:this.UserInfo.is_dinner_sys,
                type:type.join(','),
                start_time:this.searchParams.times[0],
                end_time:this.searchParams.times[1],
                enterprise_id:this.depid?this.depid:'0'
            }

            this.$serve(data).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.tableData = res.data.data
                    this.configs.total = res.data.count
                    // this.tableData.forEach(el=>{
                    //     el.apply_time =el.apply_time? formatDate(new Date(el.apply_time * 1000), 'yyyy-MM-dd'):''
                    // })
                }
            })
        },
        // 获取企业部门id
        handleCheckChange(val){
            let dept_id=[]
            val.forEach(el=>{
                dept_id.push(el.id)
            })
            this.searchParams.dept_id = dept_id.join(",");
            console.log(`677`,this.searchParams.dept_id)
        },

        handlerow(){

        },

        onSubmit(){
            this.handleMealsta()
        },
        // 分页
        handlpages(v){
            this.handleMealsta(v)
        },
        handleSizeChange(){

        },
    }
}
</script>

<style scoped>
.mealre{
    padding: 20px;
    background-color: #fff;
}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
}
</style>